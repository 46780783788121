import React from 'react';

const Testimonial = ({ text, author }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      {/* Use quotation marks to visually indicate a testimonial */}
      <p className="text-gray-600 italic mb-4">"{text}"</p>
      {/* Display the author's name if provided */}
      {author && <p className="text-gray-800 font-semibold">- {author}</p>}
    </div>
  );
};

export default Testimonial;