import React from 'react';
import Testimonial from '../molecules/Testimonial';

const TestimonialSection = () => {
  // Array of testimonial objects
  const testimonials = [
    {
      text: "The camp introduced a new way of thinking about his performance on the field. There's been a shift from qualitative to quantitative. He now talks about metrics like speed and distance on top of goals and assists. Every day I picked him up he was smiling and was excited to tell me about the matches and how they played with the data. It also added some ownership of the data since he was not just being told what happened, but was a part of the process. Thanks One Knox for providing these types of opportunities!",
      author: "Teddy's Dad"
    },
    {
      text: "My daughter really enjoyed her Soccer/STEM experience!  It was fun and an appropriate skill level for a variety of ages and relating real world technologies to her favorite sport is so great, lets her know there are many different ways to have careers in sports!",
      author: "Evie's Mom"
    },
    // Add more testimonials here as they become available
  ];

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">What Parents Are Saying</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            <Testimonial key={index} text={testimonial.text} author={testimonial.author} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;