import React from 'react';
import PageLayout from '../components/templates/PageLayout';
import Button from '../components/atoms/Button';
import LazyImage from '../components/atoms/LazyImage';

const FootyLab = () => {
  React.useEffect(() => {
    // Change favicon for FootyLab page
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = "/footylab_v2_icon.png";
    }
    return () => {
      // Reset favicon when leaving the page
      if (link) {
        link.href = "/dr_icon.png";
      }
    };
  }, []);

  return (
    <PageLayout>
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold mb-8 text-center">footyLab: Where Soccer Meets Data Science</h1>
        <div className="mb-12 text-center">
          <LazyImage
            src="/footylab_v2_vertical.png"
            alt="footyLab Logo"
            className="mx-auto mb-8 w-auto"
            style={{maxWidth: '360px', maxHeight: '200px'}}
          />
          <p className="text-xl mb-6">
            footyLab is a groundbreaking program that combines the excitement of soccer with cutting-edge data science education. Using professional-grade analytics tools, students aged 10-18 learn to analyze their own performance data, developing crucial STEM skills along the way.
          </p>
          <p className="text-xl font-bold">
            Program Cost: $10/hour/student
          </p>
        </div>

        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-6">Program Highlights</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-2xl font-semibold mb-4">What You'll Learn</h3>
              <ul className="list-disc list-inside">
                <li>Python programming basics</li>
                <li>Data analysis and visualization</li>
                <li>Statistical concepts and their applications</li>
                <li>Soccer performance analytics</li>
              </ul>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-4">What You'll Do</h3>
              <ul className="list-disc list-inside">
                <li>Play soccer using professional tracking technology</li>
                <li>Analyze your own performance data</li>
                <li>Develop critical thinking and problem-solving skills by building a webapp</li>
                <li>Create data-driven strategies to improve your game</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-6">Our Technology</h2>
          <p className="mb-4">footyLab utilizes professional-grade equipment to provide a true sports analytics experience:</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-2xl font-semibold mb-4">Veo Cameras</h3>
              <p>AI-powered cameras that capture 180° footage of the entire field, allowing for comprehensive video analysis.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-4">Catapult Trackers</h3>
              <p>Wearable GPS devices that collect detailed performance data, including distance covered, speed, and acceleration.</p>
            </div>
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-6">Our Instructors</h2>
          <p className="mb-4">footyLab instructors bring a unique blend of expertise in both soccer and data science:</p>
          <ul className="list-disc list-inside">
            <li>Experienced youth soccer coaches</li>
            <li>Data scientists with backgrounds in sports analytics</li>
            <li>Educators passionate about making STEM accessible and engaging</li>
          </ul>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-6">Frequently Asked Questions</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-xl font-semibold mb-2">Who can participate in footyLab?</h3>
              <p>footyLab is designed for youth soccer players aged 10-18, regardless of their current skill level in soccer or data science.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Do I need to bring my own equipment?</h3>
              <p>No, all necessary technology and equipment will be provided during the sessions.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">How long is the program?</h3>
              <p>The program typically runs in 6-week sessions, with classes held once or twice a week.</p>
            </div>
          </div>
        </section>

        <div className="text-center">
          <Button onClick={() => window.location.href='/contact'} className="text-xl py-3 px-6">
            Enroll in footyLab
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};

export default FootyLab;
